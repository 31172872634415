import { Component, ElementRef, AfterViewInit, QueryList, ViewChildren } from '@angular/core';

import * as ScrollMagic from 'scrollmagic';
import { TimelineMax, TweenLite, Power4 } from 'gsap';

@Component({
  selector: 'app-graduation',
  templateUrl: './graduation.component.html'
})
export class GraduationComponent implements AfterViewInit {

  @ViewChildren('diploma') diploma: QueryList<ElementRef>;

  constructor() { }

  ngAfterViewInit() {
    const controller = new ScrollMagic.Controller();
    const fadeInDownTimeLine = new TimelineMax();

    this.diploma.forEach((diplomaItem) => {
      fadeInDownTimeLine.from(diplomaItem.nativeElement, 0.6,
        { autoAlpha: 0, y: -20, ease: Power4.easeOut }, '-=0.3');
    });


    const fadeInDownScene = new ScrollMagic.Scene({
      triggerElement: '.section-4',
      triggerHook: 0.2
    })
    .setTween(fadeInDownTimeLine)
    .addTo(controller);
  }

}
