import { Component, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, HostListener } from '@angular/core';

import * as ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax, TweenLite, Power4 } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { isNullOrUndefined } from 'util';

import {enableProdMode} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit {

  windowWidth: number;

  nombreAnneesRomance: number = this.calculerNombreAnnees(new Date(2014, 8, 1));

  calculerNombreAnnees(dateDebut: Date): number {
    const now = new Date();
    return now.getFullYear() - dateDebut.getFullYear();
  }

  // SLICK
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '<div class=\'nav-btn next-slide\'></div>',
    prevArrow: '<div class=\'nav-btn prev-slide\'></div>',
    dots: true,
    infinite: false,
    centerMode: true
  };

  @ViewChild('presentation', { static: false }) presentation: ElementRef;
  @ViewChild('avatarPresentation', { static: false }) avatarPresentation: ElementRef;
  @ViewChild('name', { static: false }) name: ElementRef;
  @ViewChild('job', { static: false }) job: ElementRef;

  @ViewChild('progressBar', { static: false }) progressBar: ElementRef;

  @ViewChildren('mountain') mountain: QueryList<ElementRef>;

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    const progression = window.scrollY * 100 / (document.body.clientHeight - window.innerHeight);
    this.progressBar.nativeElement.style.width = progression + '%';
  }

  constructor() {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
  }

  ngAfterViewInit() {
    const controller = new ScrollMagic.Controller();

    // Landing appearance
    TweenLite.from(this.name.nativeElement, 2, { autoAlpha: 0 }).delay(1);
    TweenLite.from(this.job.nativeElement, 2, { autoAlpha: 0 }).delay(0.5);

    // Landing parallax
    const landingParallaxTimeline = new TimelineMax();
    landingParallaxTimeline.to(this.name.nativeElement, 1, { y: 50, ease: Power4.easeOut });
    const landingParallaxScene = new ScrollMagic.Scene({
      triggerElement: '.section-1',
      triggerHook: 0,
      duration: 500
    })
    .setTween(landingParallaxTimeline)
    .addTo(controller);

    // Fade in presentation
    const fadeInPresentationTimeline = new TimelineMax();
    fadeInPresentationTimeline
      .to(this.avatarPresentation.nativeElement, 1, { autoAlpha: 0, x: -100, ease: Power4.easeIn })
      .from(this.presentation.nativeElement, 1, { autoAlpha: 0, x: 100, ease: Power4.easeOut });

    const fadeInPresentation = new ScrollMagic.Scene({
      triggerElement: '.presentation',
      triggerHook: 0.2
    })
    .setTween(fadeInPresentationTimeline)
    .addTo(controller);

    // Fade out mountains
    const fadeOutMountainTimeline = new TimelineMax();
    this.mountain.forEach(mountainItem => {
      fadeOutMountainTimeline.to(mountainItem.nativeElement, 0.5, { autoAlpha: 0 });
    });

    const fadeOutMountainScene = new ScrollMagic.Scene({
      triggerElement: '.presentation',
      triggerHook: 0.2
    })
    .setTween(fadeOutMountainTimeline)
    .addTo(controller);
  }

  scrollToAnchor(target) {
    const element = document.querySelector(target);
    if (!isNullOrUndefined(element)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  backToTop() {
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
