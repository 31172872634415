import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  navIsOpened = false;

  @Output() anchor: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  anchorClicked(target: string) {
    this.anchor.emit(target);
    this.navIsOpened = false;
  }

}
