import { Component, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';

import * as ScrollMagic from 'scrollmagic';
import { TimelineMax, Power4 } from 'gsap';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html'
})
export class SkillsComponent implements AfterViewInit {

  @ViewChildren('logo') logo: QueryList<ElementRef>;

  constructor() {}

  ngAfterViewInit() {
    const controller = new ScrollMagic.Controller();

    const fadeInDownTimeline = new TimelineMax();

    this.logo.forEach(logoItem => {
      fadeInDownTimeline.from(logoItem.nativeElement, 0.4, { autoAlpha: 0, ease: Power4.easeOut, y: 20 }, '-=0.2');
    });

    const fadeInDownScene = new ScrollMagic.Scene({
      triggerElement: '.skills',
      triggerHook: 0.6,
      duration: '50%'
    })
    .setTween(fadeInDownTimeline)
    .addTo(controller);
  }

}
