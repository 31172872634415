import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as ScrollMagic from 'scrollmagic';
import { TimelineMax, TweenLite, Power4 } from 'gsap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements AfterViewInit {

  @ViewChild('laptop', { static: false }) laptop: ElementRef;

  constructor() { }

  ngAfterViewInit() {
    const controller = new ScrollMagic.Controller();

    const rotateLaptopScene = new ScrollMagic.Scene({
      triggerElement: '.contact',
      triggerHook: 0.5
    })
    .setTween(TweenLite.fromTo(
      this.laptop.nativeElement, 2,
        { transform: 'translate(-50%, -50%) rotate3d(1,0,0,90deg)'},
        { transform: 'translate(0, 5%) rotate3d(1,0,0,0deg)',
        ease: Power4.easeOut
      }
    ))
    .addTo(controller);
  }

}
