import { Component, ViewChild, ElementRef, AfterViewInit, HostListener, OnInit } from '@angular/core';
import * as ScrollMagic from 'scrollmagic';
import { TimelineMax, TweenLite, Power4 } from 'gsap';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html'
})
export class ExperienceComponent implements AfterViewInit, OnInit {
  deviceIsMobile: boolean;
  lastWidth = 0;

  controller = new ScrollMagic.Controller();

  @ViewChild('avThird', { static: false }) avThird: ElementRef;
  @ViewChild('timeline', { static: false }) timeline: ElementRef;
  resizeTimeout: any;

  @HostListener('window:resize')
  onWindowResize() {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.deviceIsMobile = window.innerWidth < 800;

        if ( window.innerWidth !== this.lastWidth) {
          this.lastWidth = window.innerWidth;
          window.location.reload();
        }
      }).bind(this), 500);
  }

  constructor() { }

  ngOnInit() {
    this.lastWidth = window.innerWidth;
    this.deviceIsMobile = window.innerWidth < 800;
  }

  ngAfterViewInit() {
    // Fade In 3rd avatar
    const fadeInThirdAvatarScene = new ScrollMagic.Scene({
      triggerElement: '.section-3',
      triggerHook: 0.5
    })
    .setTween(TweenLite.to(this.avThird.nativeElement, 0.4, { autoAlpha: 1 }))
    .addTo(this.controller);

    if (!this.deviceIsMobile) {
      // Timeline Transalate
      const timelineTranslateTween = new TimelineMax();

      timelineTranslateTween
        .fromTo(this.timeline.nativeElement, 1, { '-webkit-transform': 'translateX(0)' }, { '-webkit-transform': 'translateX(-100%)' });

      const timelineTranslateScene = new ScrollMagic.Scene({
        triggerElement: '.section-3',
        duration: 2000,
        triggerHook: 0
      })
      .setTween(timelineTranslateTween)
      .addTo(this.controller)
      .setPin('.section-3');
    }
  }
}
